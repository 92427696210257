<template>
  <div>
    <b-form-group
      :label="titleWithColon"
      label-cols-sm="3"
      label-align-sm="right"
      invalid-feedback="กรุณาเลือกไฟล์"
      :state="!$v.files.$error"
    >
      <b-form-file
        multiple
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        accept="image/jpeg, image/png"
        @input="handleFilesInput"
        v-model="dumpFiles"
      ></b-form-file>
      <!-- accept="image/jpeg
       ,image/png
       ,video/quicktime 
       ,video/x-m4v 
       ,video/x-msvideo
       ,video/webm 
       ,video/mp4
       ,application/pdf" -->
    </b-form-group>
    <b-row v-if="this.files.length > 0" class="px-2 mb-3">
      <b-col
        v-for="(imageUrl, index) in imageUrls"
        :key="index"
        class="p-1 image-containner img-wrap"
        cols="6"
        md="4"
        lg="3"
        xl="3"
      >
        <b-badge
          class="img-delete-button show-when-hovered"
          variant="danger"
          href="#"
          @click.stop="deleteImg(index)"
          >&times;</b-badge
        >
        <b-img
          :src="imageUrl"
          alt="Uploaded Image"
          thumbnail
          fluid
          :style="{ aspectRatio: '1/1', width: '100%', objectFit: 'contain' }"
        />
        <div class="overlay-button-container">
          <b-button
            class="mx-1"
            size="sm"
            variant="secondary"
            @click="imageTransformation({ index, degrees: 90 })"
          >
            <i class="fas fa-rotate-right"></i>
          </b-button>

          <b-button
            class="mx-1"
            size="sm"
            variant="secondary"
            @click="imageTransformation({ index, flipHorizontal: true })"
          >
            <i class="my-icon icon-reflect-horizontal"> </i>
          </b-button>
          <b-button
            class="ml-1"
            size="sm"
            variant="secondary"
            @click="imageTransformation({ index, flipVertical: true })"
          >
            <i class="my-icon icon-reflect-vertical"> </i>
          </b-button>
        </div> </b-col
    ></b-row>
    <Dialog ref="Dialog"></Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import { POLLING_INTERVAL, MAX_POLL_ATTEMPS } from "@/constants";

import { eventBus } from "@/main";

import moment from "moment";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "uploadFilesForm",
  components: { Dialog },
  props: {
    category: String,
    patientId: null,
    treatmentId: null,
    labId: null,
    title: {
      type: String,
      default: "ไฟล์",
    },
  },
  data() {
    return {
      fileCategories: [
        { text: "X-Ray", value: "XRay" },
        { text: "Intra-Oral", value: "IntraOralPhoto" },
        { text: "Extra-Oral", value: "ExtraOralPhoto" },
        { text: "OPD Card", value: "OpdCard" },
        // { text: "Invoice", value: "Invoice" },
        { text: "Document", value: "Document" },
        // { text: "Model3D", value: "Model3d" },
        // { text: "Video", value: "Video" },
      ],
      dumpFiles: null,
      files: [],
      filesMetadata: [],

      imageUrls: [],
      images: [],
      rotations: [],
      flipHorizontals: [],
      flipVerticals: [],

      fileList: [],
      highestId: 0,
      pollInterval: null,
      continuePolling: true, // Flag to indicate whether to continue polling
      fetchAttempts: 0, // Counter to keep track of fetch attempts
      maxFetchAttempts: 3,
      uploadedFiles: null,

      isLoading: false,
    };
  },
  validations: {
    selectedCategory: {
      required,
    },
    files: { required },
  },
  watch: {},

  computed: {
    ...mapGetters({
      isAccessMenu: "moduleUser/getIsAccessMenu",
      getFileList: "moduleMediaFile/getFileList",
    }),
    titleWithColon() {
      return this.title + ":";
    },
  },
  methods: {
    ...mapActions({
      createMediaFiles: "moduleMediaFile/createMediaFiles",
      deleteMediaFile: "moduleMediaFile/deleteMediaFile",
      fecthMediaFiles: "moduleMediaFile/fecthMediaFiles",
    }),

    async saveFiles() {
      if (this.imageUrls?.length < 1) return;
      try {
        const filePromises = this.imageUrls.map(url =>
          this.blobFromDataURL(url)
        );
        this.files = await Promise.all(filePromises);
        await this.handleCreateMediaFile();
        await this.$nextTick();
        this.pollForUpdates(this.files);
      } catch (err) {
        console.error(err);
      }
    },
    async upLoadFileWithoutPolling() {
      if (this.imageUrls?.length < 1) return;
      try {
        const filePromises = this.imageUrls.map(url =>
          this.blobFromDataURL(url)
        );
        this.files = await Promise.all(filePromises);
        await this.handleCreateMediaFile();
        // await this.$nextTick();
        // this.pollForUpdates(this.files);
      } catch (err) {
        console.error(err);
      }
    },
    async handleCreateMediaFile() {
      await this.createMediaFiles({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        data: {
          category: this.category,
          takenAt: this.takenAt
            ? moment(this.takenAt, "YYYY-MM-DD").toISOString()
            : null,
          patientId: this.patientId ? String(this.patientId) : null,
          treatmentId: this.treatmentId ? String(this.treatmentId) : null,
          labId: this.labId ? String(this.labId) : null,
          remark: this.remark,
          filesMetadata: this.filesMetadata,
        },
        files: this.files,
      });
    },
    async handleFilesInput(e) {
      this.files = e;
      if (!this.files || this.files.length === 0) return;
      this.imageUrls = [];
      for (let i = 0; i < this.files.length; i++) {
        const imageUrl = URL.createObjectURL(this.files[i]);
        this.imageUrls.push(imageUrl);

        const image = new Image();
        image.src = imageUrl;
        this.images.push(image);

        this.rotations.push(0);
        this.flipHorizontals.push(false);
        this.flipVerticals.push(false);
      }
      this.filesMetadata =
        this.files.map(i => {
          return { contentType: i.type };
        }) || [];
    },

    async imageTransformation({
      index,
      degrees = 0,
      flipHorizontal = false,
      flipVertical = false,
    }) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const image = this.images[index];
      const imageWidth = image.width;
      const imageHeight = image.height;

      const currentRotation = this.rotations[index] || 0;
      const totalRotation = (currentRotation + degrees) % 360;

      let canvasWidth, canvasHeight;

      if (totalRotation === 90 || totalRotation === 270) {
        canvasWidth = imageHeight;
        canvasHeight = imageWidth;
      } else {
        canvasWidth = imageWidth;
        canvasHeight = imageHeight;
      }

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      ctx.translate(canvasWidth / 2, canvasHeight / 2);
      ctx.rotate((totalRotation * Math.PI) / 180);

      if (flipHorizontal) {
        ctx.scale(-1, 1);
      }
      if (flipVertical) {
        ctx.scale(1, -1);
      }

      ctx.drawImage(image, -imageWidth / 2, -imageHeight / 2);

      // Batch state updates
      this.$set(this.rotations, index, totalRotation);
      this.$set(this.flipHorizontals, index, flipHorizontal);
      this.$set(this.flipVerticals, index, flipVertical);

      const dataUrl = canvas.toDataURL(
        this.filesMetadata[index].contentType,
        1
      );
      this.$set(this.imageUrls, index, dataUrl);
    },
    blobFromDataURL(dataURL) {
      return fetch(dataURL)
        .then(response => response.blob())
        .catch(error => console.error("Error converting to blob:", error));
    },
    deleteImg(index) {
      this.files.splice(index, 1);
      this.filesMetadata.splice(index, 1);
      this.imageUrls.splice(index, 1);
      this.images.splice(index, 1);
      this.rotations.splice(index, 1);
      this.flipHorizontals.splice(index, 1);
      this.flipVerticals.splice(index, 1);
    },

    pollForUpdates(files) {
      eventBus.$emit("showSpinnerToast");
      this.uploadedFiles = files;
      this.highestId = Math.max(...this.getFileList.map(file => file.id));
      this.pollInterval = setInterval(() => {
        this.fetchFiles();
      }, POLLING_INTERVAL); // Poll every 3 seconds
    },
    async fetchFiles() {
      this.fecthMediaFiles({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        params: {
          labId: this.labId,
          treatmentId: this.treatmentId,
          patientId: this.patientId,
        },
      })
        .then(response => {
          this.fileList = response;

          const newFiles = this.fileList.filter(
            file => file.id > this.highestId
          );
          if (
            newFiles.length > 0 &&
            (this.uploadedFiles === null ||
              newFiles.length === this.uploadedFiles?.length)
          ) {
            this.continuePolling = false; // Stop polling
          } else {
            this.fetchAttempts++; // Increment fetch attempts
            // Check if reached maximum fetch attempts
            if (this.fetchAttempts >= MAX_POLL_ATTEMPS) {
              this.continuePolling = false; // Stop polling
            }
          }
        })
        .catch(error => {
          console.error("Error fetching files:", error);
        })
        .finally(() => {
          if (!this.continuePolling) {
            eventBus.$emit("hideSpinnerToast");

            clearInterval(this.pollInterval); // Stop polling
            // Update the highest ID seen so far
            if (this.fileList.length > 0) {
              this.highestId = Math.max(...this.fileList.map(file => file.id));
            }
            // this.formattedList = this.formatList();
            this.setDefaultPollingData();
          }
        });
    },
    setDefaultPollingData() {
      this.highestId = 0;
      this.pollInterval = null;
      this.continuePolling = true; // Flag to indicate whether to continue polling
      this.fetchAttempts = 0; // Counter to keep track of fetch attempts
      this.uploadedFiles = null;
    },
    setDefaultValue() {
      this.dumpFiles = null;
      this.files = [];
      this.filesMetadata = [];
      this.imageUrls = [];
      this.images = [];
      this.rotations = [];
      this.flipHorizontals = [];
      this.flipVerticals = [];
    },
  },
  mounted() {
    eventBus.$on("setDefaultValuePaymentHistroy", this.setDefaultValue);
  },
};
</script>

<style>
.my-icon {
  display: inline-block; /* Use inline-flex to vertically center the icon */
  width: 1rem; /* Set the width and height of your icon */
  height: 1rem;
  background-repeat: no-repeat;
  background-position: center center;
  vertical-align: middle; /* Ensure vertical alignment */
  filter: invert(100%);
}
.icon-reflect-vertical {
  background-image: url("~@/assets/icon/reflect-vertical.svg");
}
.icon-reflect-horizontal {
  background-image: url("~@/assets/icon/reflect-horizontal.svg");
}

.image-container {
  position: relative;
}
.overlay-button-container {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px;
}
</style>