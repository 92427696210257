<template>
  <b-modal
    modal-class="component-modal-lab-item"
    size="lg fluid"
    id="LabItemModal"
    ref="LabItemModal"
    title="เพิ่มรายการแลป"
    scrollable
    no-close-on-backdrop
    @ok="saveData"
    ok-only
    :ok-title="okTitle"
    :ok-variant="okVariant"
    @hidden="defaultValue"
  >
    <b-form-group
      label="วันที่ได้รับงาน*:"
      label-cols-sm="3"
      label-align-sm="right"
    >
      <date-picker
        v-model="$v.receivedDate.$model"
        type="date"
        format="DD MMM YY"
        :disabled-date="notAfterToday"
        placeholder
        :input-class="receivedDateInputClass"
        @input="assignDfCaldate"
      ></date-picker>
    </b-form-group>
    <b-form-group
      label="วันที่คำนวน DF*:"
      label-cols-sm="3"
      label-align-sm="right"
    >
      <date-picker
        class="col p-0"
        v-model="$v.dfCalDate.$model"
        type="date"
        format="DD MMM YY"
        placeholder
        :input-class="dfCalDateInputClass"
        :disabled="isSameDate"
      ></date-picker>
      <b-form-checkbox v-model="isSameDate" name="check-button" switch>
        <span class="description">วันเดียวกับวันรับงาน</span>
      </b-form-checkbox>
    </b-form-group>
    <b-form-group label="ชื่อคนไข้*:" label-cols-sm="3" label-align-sm="right">
      <b-input-group>
        <multiselect
          class="col p-0"
          :class="{
            'is-invalid': $v.patient.$error,
            'multiselect-black-border': !$v.patient.$error,
          }"
          v-model="$v.patient.$model"
          :options="patientsList"
          :multiple="false"
          placeholder="พิมพ์ค้นหาจากชื่อ หรือ DN."
          track-by="id"
          :loading="isSearching"
          :custom-label="customPatientLabel"
          @open="searchPatient"
          @search-change="searchPatient"
        >
          <span slot="noResult">ไม่พบรายการ กรุณาเปลี่ยนคำค้นหา</span>
        </multiselect>
        <b-input-group-append is-text>
          <b-form-checkbox
            switch
            v-model="isEveryBranch"
            v-b-tooltip.hover
            title="ค้นหาคนไข้ทุกสาขา(Beta)"
          >
            <span class="small-text">ทุกสาขา</span>
          </b-form-checkbox>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-form-group label="แพทย์*:" label-cols-sm="3" label-align-sm="right">
      <b-form-select
        :class="{
          'is-invalid': $v.doctor.$error,
        }"
        v-model="$v.doctor.$model"
        :options="getDoctorList"
        value-field="uid"
        text-field="fullName"
      >
        <template v-slot:first>
          <b-form-select-option :value="null" disabled
            >รายชื่อแพทย์</b-form-select-option
          >
        </template>
      </b-form-select>
    </b-form-group>
    <b-form-group
      label="เลขที่ invoice:"
      label-cols-sm="3"
      label-align-sm="right"
    >
      <b-form-input v-model="invoiceNo"></b-form-input>
    </b-form-group>
    <b-form-group label="ชื่อรายการ*:" label-cols-sm="3" label-align-sm="right">
      <multiselect
        class="col p-0"
        :class="{
          'is-invalid': $v.labProd.$error,
          'multiselect-black-border': !$v.labProd.$error,
        }"
        v-model="labProd"
        :options="getGroupedLabProdList"
        group-values="libs"
        group-label="category"
        :group-select="false"
        :multiple="false"
        placeholder="พิมพ์ค้านหารายการแลป"
        track-by="id"
        :custom-label="customLabel"
        selectLabel="เลือกรายการนัดหมาย"
        selectedLabel="เลือกแล้ว"
        deselectLabel="คลิกเพื่อลบออก"
        @input="assignLabItemDf($event)"
      >
        <template #option="props">
          <span v-if="props.option.$isLabel">
            {{ props.option.$groupLabel }}
          </span>
          <span v-else>
            <b-button
              class="mr-1 small"
              size="xs"
              variant="outline-info"
              :pressed="props.option.favorite"
              @click.stop="favLabProd(props.option)"
            >
              <i class="far fa-star"></i>
            </b-button>
            {{
              props.option.nameEn === props.option.nameTh
                ? props.option.nameEn
                : props.option.nameEn + " / " + props.option.nameTh
            }}
          </span>
        </template>
        <span slot="noResult">ไม่พบรายการ กรุณาเปลี่ยนคำค้นหา</span>
      </multiselect>
      <template #description>
        <span>
          เพิ่มรายการได้ที่หน้า
          <router-link :to="{ name: 'LabProductManage' }">ลิสต์แลป</router-link>
        </span>
      </template>
    </b-form-group>

    <b-form-group label="บริษัท:" label-cols-sm="3" label-align-sm="right">
      <b-row>
        <b-col cols="10">
          <b-form-select
            v-model="labCompany"
            :options="getLabCompanyList"
            value-field="id"
            text-field="nameTh"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled
                >รายชื่อบริษัทแลป</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <b-button block @click="openLabCompanyModal">
            <i class="fas fa-edit"></i>
          </b-button>
        </b-col>
      </b-row>
    </b-form-group>
    <b-form-group label="ราคา*:" label-cols-sm="3" label-align-sm="right">
      <b-input-group append="บาท">
        <b-form-input
          :class="{
            'is-invalid': $v.price.$error,
          }"
          type="number"
          number
          v-model="$v.price.$model"
        ></b-form-input>
      </b-input-group>
    </b-form-group>
    <b-form-group
      label="ส่วนแบ่งหัก DF*:"
      label-cols-sm="3"
      label-align-sm="right"
    >
      <b-row>
        <b-col cols="12" lg="6">
          <b-input-group>
            <b-form-input
              :class="{
                'is-invalid': $v.dfShare.$error,
              }"
              type="number"
              number
              v-model="$v.dfShare.$model"
            ></b-form-input>
            <template v-slot:append>
              <b-input-group-text>
                {{ shareType == 0 ? "%" : "บาท" }}
              </b-input-group-text>
            </template>
          </b-input-group>
        </b-col>
        <b-col class="align-self-center">
          <b-form-radio-group
            :class="{
              'is-invalid': $v.shareType.$error,
            }"
            v-model="$v.shareType.$model"
          >
            <b-form-radio :value="0">Percent</b-form-radio>
            <b-form-radio :value="1">Fixed</b-form-radio>
          </b-form-radio-group>
        </b-col>
      </b-row>
    </b-form-group>
    <LabImageGallery
      v-if="itemId"
      category="Invoice"
      :deleteButton="true"
      photoSize="sm"
    />
    <UploadFiles
      v-if="!isFileStorageExpired"
      ref="uploadFilesForm"
      category="Invoice"
      :title="itemId ? 'เพิ่มรูปถ่าย:' : 'รูปถ่าย:'"
      :labId="labId"
    />

    <LabCompanyModal ref="LabCompanyModal"></LabCompanyModal>
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import LabCompanyModal from "@/components/modal/LabCompany";
import UploadFiles from "@/components/form/UploadFiles";
import LabImageGallery from "@/components/card/Gallery";

import { mapActions, mapGetters, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";

import moment from "moment";

export default {
  name: "LabItemModal",
  components: {
    Dialog,
    Loading,
    LabCompanyModal,
    UploadFiles,
    LabImageGallery,
  },
  data() {
    return {
      isLoading: false,
      isSearching: false,
      itemId: null,
      dfCalDate: null,
      receivedDate: null,
      patient: null,
      doctor: null,
      invoiceNo: "",
      labProd: null,
      labCompany: null,
      price: 0,
      dfShare: 50,
      shareType: 0,
      calDf: 0,
      calCf: 0,
      creationDt: null,
      creationUid: null,

      isSameDate: true,
      patientsList: [],
      labId: null,
      isEveryBranch: false,
    };
  },
  validations: {
    receivedDate: { required },
    dfCalDate: { required },
    patient: { required },
    doctor: { required },
    labProd: { required },
    price: { required },
    dfShare: { required },
    shareType: { required },
  },
  computed: {
    ...mapGetters({
      getDoctorList: "moduleUser/getDoctorList",
      getLabProductList: "moduleLab/getLabProductList",
      getGroupedLabProdList: "moduleLab/getGroupedLabProdList",
      getLabCompanyList: "moduleLab/getLabCompanyList",
      getLabCompanyBy: "moduleLab/getLabCompanyBy",
      isFileStorageExpired: "moduleUser/isFileStorageExpired",
    }),
    receivedDateInputClass() {
      return (
        "form-control d-block" +
        (this.$v.receivedDate.$error ? " is-invalid" : "")
      );
    },
    dfCalDateInputClass() {
      return "form-control " + (this.$v.dfCalDate.$error ? " is-invalid" : "");
    },

    okTitle() {
      return this.itemId ? "แก้ไขรายการ" : "เพิ่มรายการ";
    },
    okVariant() {
      return this.itemId ? "warning" : "primary";
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchPatientSearchList: "modulePatient/fetchPatientSearchList",
      fetchPatientById: "modulePatient/fetchPatientById",
      createLabExpenseItem: "moduleLab/createLabExpenseItem",
      updateLabExpenseItem: "moduleLab/updateLabExpenseItem",
      deleteLabExpenseItem: "moduleLab/deleteLabExpenseItem",
      fetchLabProductById: "moduleLab/fetchLabProductById",
      addLabProductFavorite: "moduleLab/addLabProductFavorite",
      deleteLabProductFavorite: "moduleLab/deleteLabProductFavorite",
      fecthMediaFiles: "moduleMediaFile/fecthMediaFiles",
    }),
    ...mapMutations({
      RESET_FILE_LIST: "moduleMediaFile/RESET_FILE_LIST",
    }),
    async show(item) {
      if (item?.id) {
        this.assignExpenseItem(item);
        this.$bvModal.show("LabItemModal");
        const data = await this.fecthMediaFiles({
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          params: { labId: item.id, limit: 100 },
        });
        return false;
      }
      this.RESET_FILE_LIST(); //reset file before polling
      this.$bvModal.show("LabItemModal");
    },
    assignExpenseItem(item) {
      this.itemId = item.id;
      this.dfCalDate = moment(item.dfDate).toDate();
      this.receivedDate = moment(item.recDate).toDate();
      this.doctor = item.uid;
      this.invoiceNo = item.invoiceNo;
      this.labCompany = item.mCompanyLabId;
      this.price = item.price;
      this.dfShare = item.df;
      this.shareType = item.dfType;
      this.calDf = item.dfAmount;
      this.calCf, item.cfAmount;
      this.creationDt = item.creationDt;
      this.creationUid = item.creationUid;

      this.patient = item.patient;
      this.fetchLabProductById({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        id: item.mLabId,
      }).then(res => {
        this.labProd = res.data;
      });
    },
    customLabel(libs) {
      return `${libs.nameTh}${libs.nameEn ? "-[" + libs.nameEn + "]" : ""}`;
    },
    customPatientLabel({
      dn,
      existDn,
      firstNameTh,
      lastNameTh,
      firstNameEn,
      lastNameEn,
    }) {
      let displayDn = dn + (existDn ? "/" + existDn : "");
      let fullNameTh = firstNameTh + " " + lastNameTh;
      let fullNameEng =
        firstNameEn || lastNameEn
          ? "-[" +
            (firstNameEn ? firstNameEn : "") +
            (lastNameEn ? " " + lastNameEn : "") +
            "]"
          : "";
      return `${fullNameTh}${fullNameEng}-${displayDn}`;
    },
    searchPatient(query) {
      this.isSearching = true;

      this.fetchPatientSearchList({
        keyword: query,
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        isEveryBranch: this.isEveryBranch,
        offset: 0,
        limit: 20,
      }).then(res => {
        this.patientsList = res.data;
        this.isSearching = false;
      });
    },
    calShareBaht() {
      if (this.shareType === 0) {
        // คิด share df เป็นเปอร์เซ็นต์
        this.calDf = (this.dfShare / 100) * this.price;
        this.calCf = this.price - this.calDf;
      }
      if (this.shareType === 1) {
        // คิด share df เป็นบาท
        this.calDf = this.dfShare;
        this.calCf = this.price - this.calDf;
      }
    },
    favLabProd(data) {
      this.isLoading = true;
      if (data.favorite == false) {
        this.$store
          .dispatch("moduleLab/addLabProductFavorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (data.favorite == true) {
        this.$store
          .dispatch("moduleLab/deleteLabProductFavorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    async saveData(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.calShareBaht(); //มี bug บางครั้ง CF เป็น 0
      let event = {
        id: this.itemId,
        dfDate: this.formatSaveDate(this.dfCalDate),
        recDate: this.formatSaveDate(this.receivedDate),
        patientId: this.patient?.id,
        uid: this.doctor,
        invoiceNo: this.invoiceNo,
        mLabId: this.labProd.id,
        mCompanyLabId: this.labCompany,
        price: this.price,
        df: this.dfShare,
        dfType: this.shareType,
        dfAmount: this.calDf,
        cfAmount: this.calCf,
        // creationDt: this.creationDt,
        // creationUid: this.creationUid,
      };
      this.isLoading = true;
      try {
        if (this.itemId) {
          //edit item

          try {
            this.labId = this.itemId;
            await this.$nextTick();
            await Promise.all([
              this.updateLabExpenseItem({
                clinicUrl: this.$route.params.clinicUrl,
                branchUrl: this.$route.params.branchUrl,
                event,
              }),
              this.$refs["uploadFilesForm"]?.saveFiles(),
            ]);
          } catch (error) {
            console.error(error);
          }
          this.$refs.Dialog.showToast("success", "แก้ไขรายการแลปเรียบร้อย");
        } else {
          //create new item
          delete event.id;

          const { data } = await this.createLabExpenseItem({
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
            event,
          });
          this.labId = data.id;
          await this.$nextTick();
          await this.$refs["uploadFilesForm"]?.saveFiles();
          this.$refs.Dialog.showToast("success", "เพิ่มรายการแลปเรียบร้อย");
        }
        this.$bvModal.hide("LabItemModal");
        this.$emit("fetchExpenseList");
      } catch (err) {
        console.error(err);
        this.$refs.Dialog.showToast("danger", "ทำรายการไม่สำเร็จ");
      } finally {
        this.isLoading = false;
      }
    },
    assignDfCaldate(e) {
      if (this.isSameDate) {
        this.dfCalDate = this.receivedDate;
      }
    },
    assignLabItemDf(e) {
      this.labCompany = e.companyLabId ?? null;
      this.shareType = e.dfType ?? 0;
      this.dfShare = e.df ?? (this.shareType === 0 ? 50 : 0);
    },
    formatSaveDate(date) {
      return moment(date).add(7, "h").format(); // แก้ bug ที่ db ต้อง add +1 วัน
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    defaultValue() {
      this.itemId = null;
      this.dfCalDate = null;
      this.receivedDate = null;
      this.patient = null;
      this.doctor = null;
      this.invoiceNo = "";
      this.labProd = null;
      this.labCompany = null;
      this.price = 0;
      this.dfShare = 50;
      this.shareType = 0;
      this.calDf = 0;
      this.calCf = 0;
      this.creationDt = null;
      this.creationUid = null;
      this.patientsList = [];
      this.labId = null;
      this.$v.$reset();
    },
    openLabCompanyModal() {
      this.$refs.LabCompanyModal.show();
    },
  },
};
</script>

<style scoped>
.mx-datepicker {
  width: 100%;
}
</style>