<template>
  <div ref="viewLabExpense" class="content-wrapper view-lab-expense pt-3">
    <div class="content">
      <b-card>
        <h4><i class="fas fa-file-invoice-dollar"></i> จัดการค่าแลป</h4>
        <div class="mt-3">
          <b-row>
            <b-col cols="12" sm="3">
              <b-form-group
                block
                label="ค้นหาจาก:"
                label-cols="3"
                label-align="right"
              >
                <b-form-select
                  v-model="dateType"
                  class
                  @change="searchExpenseList"
                >
                  <b-form-select-option :value="0"
                    >วันที่บันทึก</b-form-select-option
                  >
                  <b-form-select-option :value="1"
                    >วันที่รับงาน</b-form-select-option
                  >
                  <b-form-select-option :value="2"
                    >วันที่คำนวนDF</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group
                block
                label="วันที่:"
                label-cols="3"
                label-align="right"
                invalid-feedback="*กรุณาเลือกวันที่"
                :state="Boolean(dateRange[0] && dateRange[1])"
              >
                <date-picker
                  v-model="dateRange"
                  range
                  format="DD MMM YY"
                  :formatter="momentFormat"
                  :shortcuts="dateRangeShortcuts"
                  :disabled-date="notAfterToday"
                  placeholder="เลือกวันที่ต้องการค้นหา"
                  input-class="form-control"
                  @input="searchExpenseList"
                ></date-picker>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="3">
              <!-- {{ getDoctorList }} -->
              <b-form-group label="แพทย์:" label-align="right" label-cols="3">
                <b-form-select
                  v-model="doctorId"
                  :options="getDoctorList"
                  value-field="uid"
                  text-field="fullName"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null"
                      >แพทย์ทั้งหมด</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="2">
              <b-button block variant="primary" @click="openLabItemModal">
                <i class="fas fa-plus"></i>เพิ่มแลป
              </b-button>
            </b-col>
          </b-row>
        </div>

        <b-table
          id="invoiceTable"
          ref="invoiceTable"
          hover
          small
          sort-icon-left
          sort-by="id"
          :sort-desc="true"
          head-variant="light"
          :fields="fields"
          :items="invoiceList"
        >
          <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>

          <template v-slot:cell(button)="row">
            <b-button-group size="xs" class="show-when-hovered">
              <b-button variant="outline-danger" @click="deleteItem(row.item)">
                <i class="fas fa-trash-alt"></i>
              </b-button>
              <b-button
                variant="outline-secondary"
                @click="openLabItemModal(row.item)"
              >
                <i class="fas fa-edit"></i>
              </b-button>
            </b-button-group>
          </template>
        </b-table>
      </b-card>
      <LabItemModal
        ref="labItemModal"
        @fetchExpenseList="searchExpenseList"
      ></LabItemModal>
      <Dialog ref="Dialog"></Dialog>
      <Loading v-if="isLoading"></Loading>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import LabItemModal from "@/components/modal/LabItem";

import moment from "moment";

import { eventBus } from "../main";

export default {
  name: "LabExpense",
  components: {
    Dialog,
    Loading,
    LabItemModal,
  },
  data() {
    return {
      isLoading: false,
      searchFilter: {
        fromCreationDate: null, //"2021-10-07"
        toCreationDate: null,
        fromRecDate: null,
        toRecDate: null,
        fromDfDate: null,
        toDfDate: null,
        uid: null,
        pateintId: null,
        invoiceNo: null,
        mlabId: null,
        mCompanyId: null,
      },
      doctorId: null,
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "creationDt",
          label: "วันที่บันทึก",
          sortable: true,
          class: "narrow-spacing",
          formatter: "formatDate",
        },
        {
          key: "recDate",
          label: "วันที่รับงาน",
          sortable: true,
          class: "narrow-spacing",
          formatter: "formatDate",
        },
        {
          key: "dfDate",
          label: "วันคำนวนDF",
          sortable: true,
          class: "narrow-spacing",
          formatter: "formatDate",
        },
        {
          key: "invoiceNo",
          label: "เลขที่ invoice",
          class: "narrow-spacing",
        },
        {
          key: "mLab",
          label: "งานแลป",
          class: "narrow-spacing",
          formatter(i) {
            return i?.nameTh;
          },
        },

        {
          key: "mCompanyLab",
          label: "ชื่อบริษัท",
          class: "narrow-spacing",
          formatter(i) {
            return i?.nameTh;
          },
        },
        {
          key: "patient",
          label: "ชื่อคนไข้",
          class: "narrow-spacing",
          formatter(i) {
            return i?.firstNameTh + " " + i?.lastNameTh;
          },
        },
        {
          key: "doctor",
          label: "ชื่อแพทย์",
          class: "narrow-spacing",
          formatter(i) {
            return i?.fullName;
          },
        },
        {
          key: "price",
          label: "ราคา(บาท)",
          class: "narrow-spacing text-right",
        },
        {
          key: "dfAmount",
          label: "หักแพทย์",
          class: "narrow-spacing text-right",
        },
        {
          key: "cfAmount",
          label: "หักคลินิก",
          class: "narrow-spacing text-right",
        },

        {
          key: "button",
          label: "",
        },
      ],

      //date range picker
      dateType: 0,
      dateRange: [],
      isTableLoading: false,

      momentFormat: {
        //[optional] Date to String
        // stringify: (date) => {
        //   return date ? moment(date).format("LL") : "";
        // },
        //[optional]  String to Date
        parse: (value) => {
          return value ? moment(value).toDate() : null;
        },
        //[optional] getWeekNumber
        getWeek: (date) => {
          return; // a number
        },
      },
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      getLabExpenseList: "moduleLab/getLabExpenseList",
      getLabCompanyList: "moduleLab/getLabCompanyList",
      getLabCompanyBy: "moduleLab/getLabCompanyBy",
      getDoctorList: "moduleUser/getDoctorList",

      getReportDateRange: "moduleReport/getReportDateRange",
      dateRangeShortcuts: "moduleMaster/getDateRangeShortcuts",
    }),
    invoiceList() {
      let list = this.getLabExpenseList;
      if (this.doctorId === null) return list;
      return list.filter((i) => i.uid === this.doctorId);
    },

    today() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchLabExpenseList: "moduleLab/fetchLabExpenseList",
      deleteLabExpenseItem: "moduleLab/deleteLabExpenseItem",
      fetchLabCompanyList: "moduleLab/fetchLabCompanyList",
      fetchLabProductList: "moduleLab/fetchLabProductList",
    }),
    init() {
      this.dateRange = [
        new Date(this.today.getFullYear(), this.today.getMonth(), 1),
        this.today,
      ];
    },
    searchExpenseList() {
      this.isLoading = true;
      this.searchFilter = {
        fromCreationDate: null, //"2021-10-07"
        toCreationDate: null,
        fromRecDate: null,
        toRecDate: null,
        fromDfDate: null,
        toDfDate: null,
        // uid: null,
        // pateintId: null,
        // invoiceNo: null,
        // mlabId: null,
        // mCompanyId: null,
      };

      switch (this.dateType) {
        case 1:
          this.searchFilter = {
            ...this.searchFilter,
            fromRecDate: this.formatStartDateUtc(this.dateRange[0]),
            toRecDate: this.formatEndDateUtc(this.dateRange[1]),
          };
          break;
        case 2:
          this.searchFilter = {
            ...this.searchFilter,
            fromDfDate: this.formatStartDateUtc(this.dateRange[0]),
            toDfDate: this.formatEndDateUtc(this.dateRange[1]),
          };
          break;
        default:
          this.searchFilter = {
            ...this.searchFilter,
            fromCreationDate: this.formatStartDate(this.dateRange[0]),
            toCreationDate: this.formatEndDate(this.dateRange[1]),
          };
      }
      this.fetchLabExpenseList({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        event: {
          ...this.searchFilter,
          // fromCreationDate: this.dateFrom, //"2021-10-07"
          // toCreationDate: moment().format("YYYY-MM-DD"),
          // toDfDate: moment().format("YYYY-MM-DD"),
          // fromDfDate: "2021-12-19",
        },
      })
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        })
        .finally((res) => {
          this.isLoading = false;
        });
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
    formatStartDate(date) {
      return date ? moment(date).startOf("day").format() : null;
    },
    formatStartDateUtc(date) {
      return date ? moment(date).startOf("day").utc(true).format() : null;
    },
    formatEndDate(date) {
      return date ? moment(date).endOf("day").format() : null;
    },
    formatEndDateUtc(date) {
      return date ? moment(date).endOf("day").utc(true).format() : null;
    },

    deleteItem(item) {
      this.$refs.Dialog.showAlertConfirm(
        "ลบค่าแลปนี้ ?",
        `คุณต้องการรายการค่าแลปนี้`
      ).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;

          this.deleteLabExpenseItem({
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
            id: item.id,
          })
            .then((res) => {
              this.searchExpenseList();
              this.$refs.Dialog.showToast("success", "ลบรายการเรียบร้อย");
            })
            .catch((err) => {
              console.error(err);
            })
            .finally((res) => {
              this.isLoading = false;
            });
        }
      });
    },
    openLabItemModal(item) {
      this.$refs.labItemModal.show(item);
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    setDefaultValue() {
      this.dateRange = [];
      this.dateType = 0;
    },
  },
  mounted() {
    let clinicUrl = this.$route.params.clinicUrl;
    let branchUrl = this.$route.params.branchUrl;

    this.fetchLabCompanyList({
      clinicUrl,
      branchUrl,
    });
    this.fetchLabProductList({
      clinicUrl,
      branchUrl,
    });
    this.$nextTick();
    this.searchExpenseList();
  },
  updated() {},
  destroyed() {
    this.setDefaultValue();
  },
};
</script>

<style scoped>
</style>
