<template>
  <b-modal
    modal-class="component-modal-lab-company"
    id="LabCompanyModal"
    ref="LabCompanyModal"
    title="บริษัทแลป"
    no-close-on-backdrop
    hide-footer
    @hidden="setDefaultValue"
  >
    <b-form id="" autocomplete="off" @submit.stop.prevent="addLabCompany">
      <b-form-group class="mx-2" label="เพิ่มบริษัท:">
        <b-input-group class>
          <b-form-input
            class="addInput required"
            placeholder="ชื่อบริษัท(ไทย)"
            size="sm"
            required
            v-model="nameTh"
          ></b-form-input>
          <b-form-input
            class="addInput"
            placeholder="ชื่อบริษัท(Eng)"
            size="sm"
            v-model="nameEn"
          ></b-form-input>
          <template v-slot:append>
            <b-button type="submit" variant="success" size="sm">
              <i class="fas fa-plus"></i>
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </b-form>
    <hr />

    <b-form
      id="myList"
      autocomplete="off"
      @submit.stop.prevent="saveLabCompany"
    >
      <b-table
        id="dxCateTable"
        ref="dxCateTable"
        hover
        small
        sort-icon-left
        head-variant="light"
        :items="getLabCompanyList"
        :fields="fields"
      >
        <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>
        <template v-slot:cell(nameTh)="row"
          >{{ row.item.nameTh }}
          <!-- <b-form-input
            v-model="row.item.nameTh"
            class="required"
            required
            size="sm"
          ></b-form-input> -->
        </template>
        <template v-slot:cell(nameEn)="row"
          >{{ row.item.nameEn }}
          <!-- <b-form-input
            v-model="row.item.nameEn"
            class
            size="sm"
          ></b-form-input> -->
        </template>
        <template v-slot:cell(buttons)="row">
          <b-button
            size="sm"
            variant="outline-danger"
            @click="deleteLabCompanyItem(row.item)"
          >
            <i class="fas fa-trash-alt"></i>
          </b-button>
        </template>
      </b-table>
    </b-form>
    <!-- <template v-slot:modal-footer>
      <b-button type="submit" form="myList" variant="primary">บันทึก</b-button>
    </template> -->
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import { mapActions, mapGetters } from "vuex";
import "@/theme/modal/DxCateItem.scss";

export default {
  name: "LabCompanyModal",
  components: {
    Dialog,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      nameTh: "",
      nameEn: "",
      fields: [
        {
          key: "index",
          label: "",
          class: "text-center",
        },
        {
          key: "nameTh",
          label: "ชื่อบริษัท",
          sortable: true,
        },
        {
          key: "nameEn",
          label: "ชื่อบริษัท(Eng)",
          sortable: true,
        },
        {
          key: "buttons",
          label: "",
          class: "text-center show-when-hovered",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getLabCompanyList: "moduleLab/getLabCompanyList",
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchLabCompanyList: "moduleLab/fetchLabCompanyList",
      createLabCompany: "moduleLab/createLabCompany",
      deleteLabCompanyitem: "moduleLab/deleteLabCompanyitem",
    }),
    show() {
      this.$bvModal.show("LabCompanyModal");
      // this.labCompanyList = [...this.getLabCompanyList];
    },
    addLabCompany() {
      this.isLoading = true;
      this.$store
        .dispatch("moduleLab/createLabCompany", {
          event: {
            nameTh: this.nameTh,
            nameEn: this.nameEn,
          },
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
        })
        .then((res) => {
            this.showDialogToast(
            "success",
            `สร้างบริษัท "${this.nameTh}" สำเร็จ`
          );
          this.nameTh = "";
          this.nameEn = "";
          this.refreshList();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    deleteLabCompanyItem(eventData) {
      this.isLoading = true;
      // this.$store
      //   .dispatch("moduleAppointment/master/deleteLabCompanyitem", {
      //     event: eventData,
      //     clinicUrl: this.$route.params.clinicUrl,
      //     branchUrl: this.$route.params.branchUrl,
      //   })
      this.deleteLabCompanyitem({
        event: eventData,
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      })
        .then((res) => {
          // if (res.status == "200" && res.data > 0) {
          //   this.$refs.Dialog.showAlertInfo(
          //     `ประเภท "${eventData.nameTh}" ถูกเลือกใช้อยู่ !`,
          //     `ไม่สามารถลบประเภท "${eventData.nameTh}" ได้`,
          //     "error"
          //   );
          // } else if (res.status == "200" && res.data == 0) {
          //   this.$refs.Dialog.showAlertConfirm(
          //     "ลบรายการนี้ ?",
          //     `คุณต้องการลบรายการ "${eventData.nameTh}"`
          //   ).then((result) => {
          //     if (result.value) {
          //       this.deleteLabCompany(eventData);
          //     }
          //   });
          // }
          this.refreshList();
          this.showDialogToast(
            "success",
            `ลบบริษัท "${eventData.nameTh}" สำเร็จ`
          );
        })
        .catch(() => {
          this.showDialogToast(
            "warning",
            `ลบบริษัท "${eventData.nameTh}" ไม่สำเร็จ`
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
    setDefaultValue() {
      this.nameTh = "";
      this.nameEn = "";
    },
    async refreshList() {
      await this.fetchLabCompanyList({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      });
    },
  },
};
</script>

<style>
</style>